import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { sideNavProps } from "../models/page-props";
import {
    cartSelectBasin,
    filesPathname,
    navBarMenu,
    saveFiltersPathname,
    searchPathname
} from "../../utils/helper";
import { NavLink } from "react-router-dom";
import SideBar from "./SideBar";
import SettingsComponent from "../cartSelectBasin/SettingsComponent";
import { useAppDispatch, useAppSelector } from "../hooks/redux-hooks";
import { toast } from "react-toastify";
import { showCheckOutModal } from "../store/actions/modal-actions";
import { toggleViewAnalytics } from "../store/actions/wells-rigs-action";
import { toggleLeftTab } from "../store/actions/cart-basin-to-county-actions";
import { supportSectionItems } from "../../Constants/SupportSectionItems";
import { Tooltip } from 'react-tooltip';
import Intercom from '@intercom/messenger-js-sdk';
import moment from "moment";


const SideNav = ({ hideSideBar }: sideNavProps) => {
    const dispatch = useAppDispatch();
    const location = useLocation();

    const {
        alerts: { alertMsg: { data } },
        auth: {
            user: {
                user_id,
                first_name,
                last_name,
                email,
                user_hash,
                date_joined,
                profile_pic,
                trial_remaining_days,
                company_configs: {
                    free_trial_period_enabled,
                    is_trial_never_end,
                    trial_expired
                },
                subscription_status,
                unread_alert,
                renewal_date
            }
        },
        cartSelectBasinCounty: { cartListItems, },
        wellsAndRigs: { viewAnalytics, },
        cartBasinToCounty: { leftTab }, subscriptionSettings: { subscriptionData }
    } = useAppSelector(state => state);

    useEffect(() => {
        const initializeIntercomMessengerForLoggedInUser = () => {
            if (user_id && user_hash) {
                let date_joined_in_unix_timestamp_secs = Math.round((new Date(date_joined)).getTime() / 1000);
                Intercom({
                    app_id: `${process.env.REACT_APP_INTERCOM_WORKSPACE_ID}`,
                    user_id: user_id.toString(),
                    name: first_name + " " + last_name,
                    email: email,
                    created_at: date_joined_in_unix_timestamp_secs,
                    user_hash: user_hash,
                    custom_launcher_selector: '#intercom_messenger_link',
                    hide_default_launcher: true
                });
            }
        };

        initializeIntercomMessengerForLoggedInUser();
    }, [user_id]);

    const currentDate = moment().toISOString();
    const isDateCrossed = renewal_date ? moment(renewal_date).isBefore(currentDate) : false;

    return (
        <>
            {free_trial_period_enabled && !trial_expired && location.pathname === cartSelectBasin && subscription_status !== "active" ? <></> : <>
                <div className="cart-menu">
                    <div className="beta">
                        <img src="images/beta.svg" alt="" />
                    </div>
                    <div className="logo-icon">
                        <Link
                            to={(free_trial_period_enabled && trial_expired) ? cartSelectBasin : "/"}
                            className="side-menu-logo" onClick={(e) => {
                                if (free_trial_period_enabled && trial_expired) {
                                    e.preventDefault()
                                    cartListItems.length && dispatch(showCheckOutModal());
                                    toast.info("Please subscribe to plan.")
                                }
                            }}>
                            <img
                                className="ed-logo-icon"
                                src="images/ed-logo-icon.svg"
                                alt="Logo"
                            />
                            <img
                                className="ed-logo-text"
                                src="images/ed-logo-text.svg"
                                alt="Logo"
                            />
                        </Link>
                    </div>
                    <ul className={`navbar-menu scrollSection ${free_trial_period_enabled && !is_trial_never_end && trial_remaining_days !== null ? "freeTrialNav" : ""}`}>
                        {navBarMenu.map((item, index) => {
                            return (
                                <>
                                    <li id={item.label + '-tooltip-anchor'}
                                        key={index}
                                        className={`${index === 2 && (data.filter((item) => !item.read_status).length || unread_alert) ? 'unread' : ''} ${(subscription_status === "in-active" && !isDateCrossed && !is_trial_never_end) ? "disabled" : ""}`}>
                                        <NavLink

                                            style={{
                                                pointerEvents: (subscription_status === "in-active" && !isDateCrossed && !is_trial_never_end) ? 'none' : 'auto',
                                            }}
                                            onClick={(e) => {
                                                if (free_trial_period_enabled && trial_expired) {
                                                    e.preventDefault()
                                                    cartListItems.length && dispatch(showCheckOutModal());
                                                    toast.info("Please subscribe to plan.")
                                                }
                                                if (location.pathname !== saveFiltersPathname) {
                                                    if (!leftTab) {
                                                        viewAnalytics && dispatch(toggleViewAnalytics());
                                                        dispatch(toggleLeftTab(true))
                                                    } else {
                                                        dispatch(toggleLeftTab(false));
                                                    }
                                                }
                                            }}
                                            to={free_trial_period_enabled && trial_expired ? "" : item.pathname}
                                            className={({
                                                isActive,
                                                isPending
                                            }) =>
                                                isActive && !trial_expired ? "active" : ""
                                            }>
                                            <i className={item.fontAwesomeClass} />
                                        </NavLink>
                                    </li>

                                    <Tooltip
                                        place="right"
                                        anchorSelect={'#' + item.label + '-tooltip-anchor'}
                                        content={item.label}
                                    />
                                </>
                            );
                        })}
                    </ul>
                    <div
                        className={`bottomNav ${location.pathname === cartSelectBasin ? "forProfile" : ""}`}>
                        <ul className="navbar-menu">
                            {(free_trial_period_enabled || (free_trial_period_enabled && trial_expired && (subscription_status !== "active"))) && !is_trial_never_end && trial_remaining_days !== null
                                ?
                                <div className="subscribe">
                                    <p><span
                                        className="number">{trial_remaining_days || 0}</span> days <span
                                            className="text">left in your Trial</span></p>
                                    <Link to={"/subscription"} type="button"
                                        className="btn btn-primary">
                                        <img src="images/cart-icon.svg" alt="Subscription" />
                                        <span>Subscribe</span>
                                    </Link>
                                </div>
                                :
                                <></>
                            }
                            {
                                free_trial_period_enabled && !trial_expired
                                    ?
                                    <></>
                                    :
                                    <>
                                        <li id={'subscriptions-tooltip-anchor'}
                                            className={`subscriptionLi borderNone`}>
                                            <Link to="/subscription">
                                                <i className="icon subscription" />
                                            </Link>
                                        </li>
                                        <Tooltip
                                            place="right"
                                            anchorSelect={'#subscriptions-tooltip-anchor'}
                                            content={"Subscriptions"}
                                        />
                                    </>
                            }

                            <li id="settings-popup">
                                <a className={"settingbtn"}
                                    onClick={(e) => {
                                        e.preventDefault();
                                    }}>
                                    {location.pathname === cartSelectBasin
                                        ?
                                        <span className="avatar">
                                            <img src={
                                                profile_pic
                                                    ? `${process.env.REACT_APP_ED_DATA_CDN_API}/profile_pic/${profile_pic}`
                                                    : "images/profile-pic.png"
                                            } alt="profile-pic" />
                                        </span>
                                        :
                                        <i className="icon settings" />
                                    }
                                </a>
                            </li>
                            <Tooltip place="right-start"
                                anchorSelect="#settings-popup" clickable
                                className={"settings-popup"}>
                                <SettingsComponent />
                            </Tooltip>

                            <li id={'support-popup'}>
                                <a onClick={(e) => {
                                    e.preventDefault();
                                }}>
                                    <i className="icon support" />
                                </a>
                            </li>
                            <Tooltip place="right"
                                anchorSelect="#support-popup" clickable
                                className={"support-popup"}>
                                {supportSectionItems.map((item, index) => {
                                    return (
                                        item.name === 'chat' ?
                                            <a key={index}
                                                id={'intercom_messenger_link'}
                                                className={[1, 4].includes(index) ? "bottom-border" : ""}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                }}>
                                                <i className={item.iconClass} />
                                                <span>{item.label}</span>
                                            </a>
                                            :
                                            <Link key={index} to={item.link}
                                                target={"_blank"}
                                                className={[1, 4].includes(index) ? "bottom-border" : ""}>
                                                <i className={item.iconClass} />
                                                <span>{item.label}</span>
                                            </Link>
                                    )
                                })}
                            </Tooltip>
                        </ul>
                    </div>
                </div>

                {!hideSideBar && <SideBar />}
            </>
            }
        </>
    );
};

export default SideNav;
