import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "../hooks/redux-hooks";
import {
    handleAnalyticsProdFilters
} from "../store/actions/wells-rigs-action";
import { ActionType } from "../models/redux-models";
import {
    ANALYTICS_CUM_TAB, LINE_CHART_MAX_ITEM_ALLOWED, DropDownOption, capitalize
} from "../../utils/helper";

const AggregationDropdown = () => {
    const {
        wellsAndRigs: {
            analyticsData: {
                type,
                normalized,
                action,
            },
            selectedWellRigOrPermitList,
        },
    } = useAppSelector((state) => state);
    const dispatch = useAppDispatch();

    const [state, setState] = useState<{ showDropDownOption: boolean }>({
        showDropDownOption: false
    })

    const { showDropDownOption } = state;


    return (
        <>
            <div className={"aggregation"}>
                Aggregation: <span className="cursor" onClick={() => setState((prev) => ({ ...prev, showDropDownOption: !prev.showDropDownOption }))}>{capitalize(action)}</span>
                <div className={showDropDownOption ? "dropmenu" : "d-none"}>
                    <ul>
                        {DropDownOption.map((item, index) => {
                            return <li key={index}
                                className={`${item.value === "none" && selectedWellRigOrPermitList.length > LINE_CHART_MAX_ITEM_ALLOWED ? "disabled" : "cursor"} ${action === item.value ? "selected" : ''}`}
                                onClick={(() => {
                                    if (item.value === "none" && selectedWellRigOrPermitList.length > LINE_CHART_MAX_ITEM_ALLOWED) {
                                        return
                                    }
                                    let oilMaybeCum = 'oil'
                                    let gasMaybeCum = 'gas'
                                    let actionMaybeNormalized: string = item.value || 'none'
                                    if (type === ANALYTICS_CUM_TAB) {
                                        oilMaybeCum = `${oilMaybeCum}-cum`
                                        gasMaybeCum = `${gasMaybeCum}-cum`
                                    }
                                    if (normalized) {
                                        actionMaybeNormalized = `${item.value}-normalized`
                                    }
                                    dispatch(handleAnalyticsProdFilters({ action: item.value as ActionType, type }));
                                    setState((prev) => ({ ...prev, showDropDownOption: false }));

                                })}>{item.label}</li>
                        })}
                    </ul>
                </div>
            </div>

        </>
    );
};

export default AggregationDropdown;
