import React, { useMemo } from "react";
import { cardBottomProps } from "../models/page-props";
import { USDollar } from "../../utils/helper";
import { useAppDispatch, useAppSelector } from "../hooks/redux-hooks";
import { hideCheckOutModal, showCheckOutModal, showSiteLoader, hideSiteLoader } from "../store/actions/modal-actions";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { PreviewInvoiceLineItems } from "../models/redux-models"
import { clearSubscriptionData, freeTrialSubscription } from "../store/actions/subscription-settings-actions";
import { clearCartItemsList, createOrUpdateSubscription, setInvoiceLineItemsAndTotal } from "../store/actions/cart-select-basin-county-actions";
import { loadUser } from "../store/actions/auth-actions";

function CardBottom(props: cardBottomProps) {
    const { cancelClick, total } = props;
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const {
        auth: {
            user: {
                access_token,
                company_data: { company_id, allowed_sub_user },
                company_configs: { free_trial_period_enabled, trial_expired },
                subscription_status
            },
        },
        companySettings: {
            address: { phone_no, first_address, city, state,
                zip_code, },
        },
        cartSelectBasinCounty: {
            // cartItemsTotalPerMonth,
            cartModified,
            yearly,
            yearlyDiscountPercent,
            yearlyBillToday,
            monthlyBillToday
        },
        billingSettings: { paymentMethodsData },
        subscriptionSettings: { upgrade_subscription, subscriptionData }
    } = useAppSelector((state) => state);
    const {
        company_name,
        billing_email,
    } = paymentMethodsData || {};

    const subtotal = Number(((yearlyBillToday ?? 0) / ((100 - yearlyDiscountPercent) / 100)).toFixed(2))

    const previewInvoicePayload: PreviewInvoiceLineItems = useMemo(() => {
        return {
            billing_email: billing_email || "",
            company_name: `${company_name}`,
            company: company_id as number,
            billing_cycle: yearly ? "yearly" : "monthly",
            billing_address: {
                line1: first_address,
                city: city,
                state: state,
                postal_code: zip_code,
                country: "US",
            },
            phone_no: phone_no,
            preview_invoice_line_items: true
        }
    }, [yearly, billing_email, company_id, first_address, city, zip_code, phone_no, company_name])
    return (
        <div className="bottom-part">
            {
                free_trial_period_enabled && !upgrade_subscription ? <>
                    <div className={`promocode ${free_trial_period_enabled && !trial_expired ? "d-none" : ""}`}><Link to="">Add promo code</Link></div>
                    <div className="totalb">
                        <p className="totalaftertrial">Total after trial<span>{USDollar.format(total)}</span></p>
                        <p className="totaldue">Total due today<span>$0.00</span></p>
                    </div>
                    <button type="button" onClick={() => {
                        dispatch(freeTrialSubscription({ amount: total, seat_count: subscriptionData?.subscription?.allowed_user || allowed_sub_user || 1 })).then(res => {
                            const { status, msg } = res
                            if (status === 200) {
                                subscriptionData === null && dispatch(loadUser());
                                dispatch(hideCheckOutModal());
                                dispatch(clearCartItemsList());
                                dispatch(clearSubscriptionData())
                                toast.success(msg);
                                navigate("/search")
                            } else {
                                toast.error(msg)
                            }
                        })
                    }} className="btn btn-green width100">Start Your Free Trial</button>
                </> : <>
                    {yearly ? <div className="sub-total">
                        Sub total: <span>{yearly ? USDollar.format(Number(((yearlyBillToday ?? 0) / ((100 - yearlyDiscountPercent) / 100)).toFixed(2))) : 0}</span>
                    </div> : <></>}
                    {yearly ? <div className="discount">
                        Discount ({`${yearlyDiscountPercent}`}%): <span>{USDollar.format(Number((subtotal * (yearlyDiscountPercent / 100)).toFixed(2)))}</span>
                    </div> : <></>}
                    <div className="total">
                        Total: <span>{yearly ? USDollar.format(Number((yearlyBillToday ?? 0).toFixed(2)))
                            : USDollar.format(Number((monthlyBillToday ?? 0).toFixed(2)))}</span>
                    </div>
                    <div className="button">
                        <button
                            type="button"
                            className="btn btn-cancel"
                            onClick={cancelClick}
                        >
                            Cancel
                        </button>
                        <button
                            type="button"
                            className="btn btn-green"
                            disabled={!cartModified ? true : false}
                            onClick={async () => {
                                if (company_id && !((free_trial_period_enabled && !trial_expired) || allowed_sub_user===0)) {
                                    dispatch(showSiteLoader())
                                    const res = await dispatch(createOrUpdateSubscription(access_token, previewInvoicePayload))
                                    if (res.status === 200 && res.data.invoice_line_item_payload) {
                                        dispatch(setInvoiceLineItemsAndTotal(res.data.invoice_line_item_payload))
                                        dispatch(showCheckOutModal());
                                    } else {
                                        dispatch(hideSiteLoader())
                                        toast.error("Problem getting cart prices.");
                                    }

                                } else if (company_id) {
                                    dispatch(showCheckOutModal());
                                } else {
                                    toast.info("Please add your company details.");
                                    navigate("/company-settings", {
                                        state: { checkout: true },
                                    });
                                }
                            }}
                        >
                            Checkout
                        </button>
                    </div>
                </>
            }
        </div>
    );
}

export default CardBottom;
