import React from "react";
import { cardProps } from "../models/page-props";
import { FormatLongNumber, USDollar, calculateDiscount, formatUSDCurrency } from "../../utils/helper";
import moment from "moment";
import { useAppSelector } from "../hooks/redux-hooks";

const Card = (props: cardProps) => {
    const { item, removeItem, free_trial_period_enabled, trial_expired } = props;
    const { cartSelectBasinCounty: { yearly, yearlyDiscountPercent } } = useAppSelector(state => state)
    const go_national = "plan_name" in item && item.plan_name === "National";
    const yearlyDiscountFraction = (100 - yearlyDiscountPercent) / 100
    return (
        <>
            <div className="selected-basin-box">
                <div className="basin-left">
                    <div className="small-map">
                        {/* <img src="images/basin-small-map.svg" alt="" /> */}
                        <img src={go_national ? `${process.env.REACT_APP_ED_DATA_CDN_API}/company_logo/${item.png}` : item.png} alt="" />
                    </div>
                    {/* <div className={`price ${free_trial_period_enabled ? "cross-price" : ""}`}>
                        <div className="itemPrice">{yearly ? `${formatUSDCurrency(item.price - Number(calculateDiscount(item.price, yearlyDiscountPercent)))}` : USDollar.format(item.price)}/mo</div>
                        {yearly ? <span className="fullitemprice">{`${USDollar.format(item.price)}/mo`}</span> : <></>}
                    </div> */}
                </div>
                <div className="basin-right">
                    <a
                        href="void:(0)"
                        onClick={(e) => {
                            e.preventDefault();
                            removeItem(item.id, "basin_name" in item ? 1 : go_national ? 3 : 2);
                        }}
                        className="cross"
                    >
                        <i className="fa-solid fa-xmark"></i>
                    </a>
                    <div className="title">
                        {go_national ? item['plan_name'] : "basin_name" in item
                            ? `${item.basin_name}`
                            : `${item.county_name}`}
                        {item.end_period && item.is_deleted ? (
                            <span>
                                Cancelled - Active Untill:{" "}
                                {moment(item.end_period).format("MMM-DD-YYYY")}
                            </span>
                        ) : (
                            <></>
                        )}
                    </div>
                    <div className="basin-list">
                        {/* <a
                        href="void:(0)"
                        onClick={(e) => e.preventDefault()}
                        className="down-arrow"
                    >
                        <i className="fa-solid fa-angle-down"></i>
                    </a> */}
                        <table border={0} cellSpacing={"0"} cellPadding="0">
                            <tbody>
                                {"basin_name" in item && (
                                    <tr>
                                        <td>County count</td>
                                        <td>
                                            <span>{item.county_count}</span>
                                        </td>
                                    </tr>
                                )}
                                {"county_name" in item && (
                                    <tr>
                                        <td>State or Province</td>
                                        <td>
                                            <span>{item.state_abbr}</span>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                    {free_trial_period_enabled && !trial_expired ? <div className="price cross-price">
                        <div className="afteritemPrice">
                            Price after free trial:<br />
                            <span>{`${USDollar.format(item.price_per_month)}/mo`}</span>
                        </div>
                    </div> : <div className={`price ${free_trial_period_enabled ? "cross-price" : ""}`}>
                        <div className="itemPrice">{yearly ? `${formatUSDCurrency(item.price / 12)}` : USDollar.format(item.price)}/mo</div>
                        {yearly ? <span className="fullitemprice">{`${USDollar.format(item.price / yearlyDiscountFraction / 12)}/mo`}</span> : <></>}
                    </div>
                    }
                </div>
            </div >
        </>
    );
};

export default Card;
