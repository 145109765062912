import React, { useEffect, useLayoutEffect } from "react";
import withSideNav from "../HOC/withSideNav";
import SideContainer from "./SideContainer";
import CommonMainContent from "./CommonMainContent";
import {useAppDispatch, useAppSelector} from "../hooks/redux-hooks";
import {
    clearProductionData,
    clearRigsData,
    clearWellsData,
    fetchColumnProperties,
    handlePageChange,
    handleResizableHeight,
    handleSelectedAoiData,
    handleSelectedWellRigOrPermitUIDList,
    setRapidAPIFromInput,
    toggleViewAnalytics
} from "../store/actions/wells-rigs-action";
import {toggleLeftTab} from "../store/actions/cart-basin-to-county-actions";
import {useLocation} from "react-router-dom";
import {
    aoiPathname,
    cartSelectBasin,
    saveFiltersPathname,
    searchPathname
} from "../../utils/helper";
import {resetApiList, resetShapeFileList} from "../store/actions/files-actions";
import {
    clearAoiList,
    setAoiNameSelForEdit,
    toggleSettDrawer
} from "../store/actions/aoi-actions";
import {handleUpgradeSubscription} from "../store/actions/subscription-settings-actions";

const CommonView = () => {

    const {
        //  For search page
        wellsAndRigs: { viewAnalytics, loadColumnProperties, selectedAoiData: { aoi_id }, rapidAPIFromInput },
        cartBasinToCounty: { leftTab, showAdvancedFilter },
        files: { shapeFileListLoading, apiListLoading },
        aoi: { aoiDataLoading, toggleSettingDrawer },
        subscriptionSettings: { upgrade_subscription }
    } = useAppSelector(state => state);
    const dispatch = useAppDispatch();
    const location = useLocation();

    useLayoutEffect(() => {
        //open the left tab when path changes
        dispatch(toggleLeftTab(true))
        //  For search page
        viewAnalytics && location.pathname === searchPathname && dispatch(toggleViewAnalytics());
        loadColumnProperties && (location.pathname === searchPathname || location.pathname === aoiPathname) && dispatch(fetchColumnProperties());
        if (aoi_id && (location.pathname === searchPathname || location.pathname === aoiPathname)) {
            //closing setting drawer if it is open
            if (toggleSettingDrawer) {
                toggleSettingDrawer && dispatch(toggleSettDrawer());
                dispatch(
                    setAoiNameSelForEdit({ aoi_id: 0, aoi_name: '' })
                );
            }
            dispatch(handleSelectedAoiData({aoi_id: 0}));
            dispatch(handleSelectedWellRigOrPermitUIDList([]));
            dispatch(handlePageChange(1));
            dispatch(clearProductionData());
            dispatch(clearWellsData());
            dispatch(clearRigsData());
        }

        return () => {
            //  For search page
            if (location.pathname === searchPathname) {
                viewAnalytics && dispatch(toggleViewAnalytics());
                dispatch(toggleLeftTab(true))
            }
            if (location.pathname !== searchPathname) {
                rapidAPIFromInput && dispatch(setRapidAPIFromInput(""))
            }
            // if (location.pathname === aoiPathname) {
            !shapeFileListLoading && dispatch(resetShapeFileList())
            !apiListLoading && dispatch(resetApiList())
            !aoiDataLoading && dispatch(clearAoiList())
            dispatch(handleResizableHeight(`17rem`));

            // }
            if (location.pathname === cartSelectBasin && upgrade_subscription) {
                dispatch(handleUpgradeSubscription(false))
            }
        }
        // eslint-disable-next-line
    }, [location.pathname])

    useEffect(() => {
        let myElement = document.getElementById("filterToggleMove");
        if (myElement) { // Ensure myElement is not null
            if (!leftTab && location.pathname === searchPathname) {
                myElement.classList.add(`${showAdvancedFilter ? "advancedfilterToggle" : "filterToggle"}`);
                myElement.classList.remove("resetcart-map");
            } else {
                myElement.classList.remove(`${showAdvancedFilter ? "advancedfilterToggle" : "filterToggle"}`);
                location.pathname === searchPathname ? myElement.classList.add("resetcart-map") : myElement.classList.remove("resetcart-map");
            }
        }
    }, [leftTab, location.pathname])
    return (
        <>
            <SideContainer/>
            {location.pathname !== saveFiltersPathname ?
                <CommonMainContent/> : <></>}

        </>
    );
};

export default withSideNav(CommonView, true, true);
