import React, { useEffect } from "react";
import AdvancedFilter from "./AdvancedFilter";
import TabSection from "./TabSection";
import { useAppDispatch, useAppSelector } from "../../hooks/redux-hooks";
import { handleDownloadColMsg, handleResizableHeight, handleShowAndHideSegmentDropDown, showHideAdvFilter } from "../../store/actions/wells-rigs-action";
import LottieAnimationforTable from "../LottieAnimationforTable";
import DeleteConfirmationModal from "../Modal/DeleteConfirmationModal";
import { toggleDownloadColMsgModal } from "../../store/actions/modal-actions";
import useWindowDimensions from "../../hooks/useWindowDimension";
import DownloadColSection from "./DownloadColSection";

function WellsAndRigsCom() {
    const {
        wellsAndRigs: { comp, fullScreen, advFilter, showTableLoader, downloadColMsg, downloadCol, fullScrnAnalytics, csvDownOpt },
        modal: { downloadColMsgModal }
    } = useAppSelector((state) => state);
    const dispatch = useAppDispatch();
    useEffect(() => {
        return () => {
            advFilter && dispatch(showHideAdvFilter());
            dispatch(handleShowAndHideSegmentDropDown(false))
        };
        // eslint-disable-next-line
    }, []);
    const handleClose = () => {
        dispatch(toggleDownloadColMsgModal())
        dispatch(handleDownloadColMsg(""))
    }

    const { height } = useWindowDimensions()


    const handleDrag = (event: React.DragEvent<HTMLDivElement>) => {
        //prevent drag of table column
        sessionStorage.setItem("HeightDragging", JSON.parse(`true`))

    };

    const handleDragEnd = (event: React.DragEvent<HTMLDivElement>) => {
        if (event.clientY) {
            const newHeight = (height - event.clientY - 106) / 10;
            // Here you can perform any actions you need to do when dragging ends
            dispatch(handleResizableHeight(`${newHeight < 17 ? 17 : newHeight > (height - 110) / 10 ? (height - 110) / 10 : newHeight}rem`));
            sessionStorage.getItem('HeightDragging') && sessionStorage.removeItem('HeightDragging')
            // console.log({ "test": height, "client": event.clientY, "1": parseFloat(getComputedStyle(document.documentElement).fontSize), newHeight, test1: newHeight > (height - 110) / 10 ? (height - 110) / 10 : newHeight })
        }

    };



    return (
        <div
            className={
                comp
                    ? `search-result-con ${fullScreen ? "search-result-con fullscreen" : ""
                    }`
                    : "d-none"
            }
        >
            {csvDownOpt ? <DownloadColSection /> : <></>}
            <div
                // className="draggableTable"
                className={`draggableTable ${fullScrnAnalytics ? "draggableLineNone" : ''}`}
                // style={{ border: '1px solid red' }}
                draggable={fullScrnAnalytics ? false : true}
                onDrag={fullScrnAnalytics ? () => { } : handleDrag}
                onDragEnd={fullScrnAnalytics ? () => { } : handleDragEnd}
            ></div>
            {/* Well Timelines start*/}
            {/* <div className="wellTimeline">
                <div className="header">
                    Well Timeline
                    <span className="close"><img src="images/close.svg" alt="" /></span>
                </div>
                <div className="welltimelineDetails">
                    <ul>
                        <li>
                            <span className="name">Permit</span>
                            <i className="icon"><img src="images/permit.svg" alt="" /></i>
                            <span className="date">Feb 10, 2024</span>
                            <span className="source">Source: County</span>
                        </li>
                        <li>
                            <span className="name">Rig</span>
                            <i className="icon"><img src="images/rig.svg" alt="" /></i>
                            <span className="date">Feb 10, 2024</span>
                            <span className="source">Source: County</span>
                        </li>
                        <li>
                            <span className="name">DUC</span>
                            <i className="icon"><img src="images/duc.svg" alt="" /></i>
                            <span className="date">Feb 10, 2024</span>
                            <span className="source">Source: County</span>
                        </li>
                        <li>
                            <span className="name">Face Crew</span>
                            <i className="icon"><img src="images/face.svg" alt="" /></i>
                            <span className="date">Feb 10, 2024</span>
                            <span className="source">Source: County</span>
                        </li>
                        <li>
                            <span className="name">Well Completed</span>
                            <i className="icon"><img src="images/oil-well.svg" alt="" /></i>
                            <span className="date">Feb 10, 2024</span>
                            <span className="source">Source: County <br/>Wellbores: 3</span>
                        </li>
                    </ul>
                </div>
            </div> */}
            {/* Well Timelines end*/}
            <AdvancedFilter />
            <TabSection />
            {showTableLoader && downloadCol && <LottieAnimationforTable />}
            {downloadColMsgModal && (
                <DeleteConfirmationModal
                    show={downloadColMsgModal}
                    handleClose={handleClose}
                    confirmBtnClick={handleClose}
                    closeBtn={true}
                    content={<p>{downloadColMsg}</p>}
                />
            )}
        </div>
    );
}

export default WellsAndRigsCom;
