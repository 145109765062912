import { useState, useEffect, useRef } from "react";
import { Tooltip } from 'react-tooltip'
import { getSingleWellData, setRapidAPIMessage, setRapidAPIFromInput, setRapidAPIOnBlur } from "../store/actions/wells-rigs-action"
import { useAppDispatch, useAppSelector } from "../hooks/redux-hooks";
import { toast } from "react-toastify";
import { isInstanceOfWellsAndPermitsObject } from "../common/Map/helper"

const RapidAPIFilter = () => {
    const dispatch = useAppDispatch();
    const {
        wellsAndRigs: { selectedWellRigOrPermitList, rapidAPIMessage, rapidAPIFromInput },
    } = useAppSelector((state) => state);

    const [oldApiFromInput, setOldApiFromInput] = useState<string>('');
    const [localApiFromInput, setLocalApiFromInput] = useState<string>('');
    const [errorText, setErrorText] = useState<string>('');

    const getSelectedWellAPI = () => {

        if (selectedWellRigOrPermitList && selectedWellRigOrPermitList.length === 1 && isInstanceOfWellsAndPermitsObject(selectedWellRigOrPermitList[0])) {
            return selectedWellRigOrPermitList[0].well_api
        }

    }

    const timeoutRef = useRef<NodeJS.Timeout | null>(null);

    const setDebouncedState = (newValue: string) => {
        // Clear the previous timeout if it exists
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }

        // Set a new timeout to update the state
        timeoutRef.current = setTimeout(() => {
            dispatch(setRapidAPIFromInput(newValue.trim()))
            timeoutRef.current = null;
        }, 5000);
    };


    useEffect(() => {
        // if we set the selected row id to the api from input,
        // and then clear or change that selected row id, then the
        // text should get cleared out from the input form
        const wellAPI = getSelectedWellAPI()
        if (wellAPI && wellAPI === rapidAPIFromInput && wellAPI != oldApiFromInput) {
            setOldApiFromInput(rapidAPIFromInput)
        } else if ((!wellAPI || wellAPI !== rapidAPIFromInput) && oldApiFromInput) {
            const elem = document.getElementById("rapid_api") as HTMLInputElement;
            if (elem && elem.value) {
                elem.value = '';
            }
            setOldApiFromInput('')
        }
    }, [selectedWellRigOrPermitList])

    useEffect(() => {
        if (rapidAPIMessage && rapidAPIMessage !== "success" && rapidAPIMessage !== "loading") {
            toast.error(rapidAPIMessage)
            setErrorText("")
            setDebouncedState("")
            setLocalApiFromInput("")
            setOldApiFromInput("")
            dispatch(setRapidAPIMessage(""))
        } else if (rapidAPIMessage === "success") {
            dispatch(setRapidAPIMessage(""))
        }
    }, [rapidAPIMessage])

    const handleRapidAPIChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        setDebouncedState(value)
        setLocalApiFromInput(value.trim())
        if (!value || value.length == 0) {
            setErrorText("")
        }
        handleValidation(true)

    };

    const handleValidation = (onlyRemove: boolean) => {
        if (localApiFromInput && localApiFromInput.length > 0 && isNaN(parseInt(localApiFromInput))) {
            if (!onlyRemove) {
                setErrorText("API's must be a number")
            }
            return false
        } else if (localApiFromInput && localApiFromInput.length < 10 && localApiFromInput.length > 0) {
            if (!onlyRemove) {
                setErrorText("API's should be at least 10 digits long")
            }
            return false
        } else if (localApiFromInput && localApiFromInput.length > 20) {
            if (!onlyRemove) {
                setErrorText("API's should be less than 20 digits long")
            }
            return false
        } else if (localApiFromInput && localApiFromInput.length > 0) {
            setErrorText("")
            return true
        }
    }
    const handleSearch = () => {
        const validated = handleValidation(false)
        if (!validated) {
            return
        }
        if (!errorText && localApiFromInput && localApiFromInput.length > 0 && !rapidAPIMessage) {
            dispatch(setRapidAPIMessage("loading"))
            dispatch(getSingleWellData(localApiFromInput, true));
        }
    }
    const onKeyDownHandler = (e: React.KeyboardEvent<HTMLInputElement>) => {

        if (e.code === 'Enter' ||
            e.code === 'NumpadEnter' ||
            e.key === 'Enter' ||
            e.key === 'NumpadEnter' ||
            e.which === 13 ||
            e.keyCode === 13) {
            e.preventDefault()
            handleSearch();
        }
    };

    return (
        <div>

            <div className="left-row left-padding">
                <img src="images/green-lighting.svg" alt="lighting" className="margin-bottom-point-5" />
                <label htmlFor="">
                    Rapid API Filter
                </label>
                <i data-tooltip-id="rapid-API-tooltip"
                    data-tooltip-html="<div><span>Enter an API to quickly filter, select, and</span><br/><span>zoom to any well in your subscription.</span><br/><span> This action will change all other filters</span><br/><span>to the default state.</span></div>"
                    data-tooltip-place="top"
                    data-tooltip-variant="light">
                    <img src="images/info-tooltip.svg" alt="tooltip" className="margin-bottom-point-5" />
                </i>

            </div>
            <div className="rapid-API-filter">
                <form onKeyDown={(e: any) => onKeyDownHandler(e)}>
                    <div className={errorText ? "center-row red-outline" : "center-row"}>

                        <input
                            className="form-control"
                            placeholder="Enter a single API"
                            name="rapid_api"
                            id="rapid_api"
                            onChange={handleRapidAPIChange}
                            defaultValue={rapidAPIFromInput || ''}
                            onFocus={()=>dispatch(setRapidAPIOnBlur(true))}
                            onBlur={()=>dispatch(setRapidAPIOnBlur(false))}
                        />
                        <button onClick={(e) => {
                            e.preventDefault()
                            handleSearch()
                        }} className="btn btn-primary btn-input-height">
                            <img src="images/search.svg" className="search-input-icon" />
                        </button>
                    </div></form>
                <span className={errorText ? "rapid-API-error-helper-text" : "rapid-API-error-helper-text-hidden"}>{errorText || "hidden"}</span>
            </div >
            <Tooltip id="rapid-API-tooltip" />
        </div >

    );
};

export default RapidAPIFilter;
