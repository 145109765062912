import React, { useEffect, useRef, useState } from "react";
// import SearchComponent from "./SearchComponent";
import { useLocation, useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../hooks/redux-hooks";
import {
    clearAoiList,
    clearAoiNameSelForEdit,
    handleUsingMapCreateAoi,
    updateAoiData,
    // clearAoiNameSelForEdit,
} from "../store/actions/aoi-actions";
import { MapComponent, MapType } from "./MapComponent";
import WellsAndRigsCom from "./WellsAndRigs/WellsAndRigsCom";
import AnalyticsRightSidebar from "../aoi/AnalyticsRightSidebar";
import { aoiPathname, filesPathname, saveFiltersPathname, searchPathname } from "../../utils/helper";
import {
    // getWellsAndPermitList,
    handleResizableWidth, handleShowAndHideSegmentDropDown, handleShowAndHideTable, handleTabIndex, showHideAdvFilter, showHideColProperties,
    // showHideCsvDownOpt,
    toggleViewAnalytics
} from "../store/actions/wells-rigs-action";
import {
    // cartBasinSearchList,
    // clearCartBasinSearchList,
    toggleLeftTab
} from "../store/actions/cart-basin-to-county-actions";
// import CartBasinSearchList from "../cartBasinToCounty/CartBasinSearchList";
import AnalyticsFullScreen from "./AnalyticsFullScreen";
import { toggleCreateAoiModal } from "../store/actions/modal-actions";
import { toast } from "react-toastify";
import CreateAoiModal from "../aoi/CreateAoiModal";
import { UpdateAoiBufferDistanceSubmitForm } from "../models/submit-form";
import { aoiBufferDistanceUpdateInputValidation } from "../../Helper/validation";
import InputComponent from "./InputComponent";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

function CommonMainContent() {
    const location = useLocation();
    const {
        auth: {
            user: { access_token },
            isAuthenticated
        },
        aoi: {
            max_allowed_aoi,
            usingMapCreateAoi,
            savedAoiData,
            aoiNameSelForEdit: { aoi_name, aoi_id, buffer_distance },
        },
        modal: { createAoiModal },
        wellsAndRigs: {
            tabIndex,
            // wellsData: { wellsDataLoading,
            // data: wellsDataList,
            // },
            // permitsData: { permitsDataLoading,
            //  data: rigsDataList,
            // },
            // rigsData: { rigsDataLoading,
            // data: permitDataList,
            // },
            // productionData: { productionDataLoading,
            //  data: productionDataList,
            // },
            // selectedAoiData: { aoi_id: id },
            // filterSearch,
            // wellsPage,
            // permitsPage,
            // rigsPage,
            // productionPage,
            // sort_by,
            // sort_order,
            advFilter,
            viewAnalytics,
            // analyticsTabIndex,
            colProperties,
            // csvDownOpt,
            showSegmentDropDown,
            showTable,
            resizableWidth,
            // downloadCol,
            // allCol,
            tableCol,
            rigsTableCol,
            productionCol,
            // uid
        },
        cartBasinToCounty: { leftTab },
        esri: { lastAddedGeom, lastAddedGeomCRS,
        }
    } = useAppSelector((state) => state);
    const dispatch = useAppDispatch();
    // const col = tabIndex === 0 || tabIndex === 2 ? tableCol : tabIndex === 1 ? rigsTableCol : productionCol;

    useEffect(() => {
        if (aoi_name) {
            reset();
            dispatch(clearAoiNameSelForEdit());
        }
        if (location.pathname === aoiPathname) {
            advFilter && dispatch(showHideAdvFilter());
            viewAnalytics && dispatch(toggleViewAnalytics())
        }

        // eslint-disable-next-line
    }, [location.pathname])

    const draggableRef = useRef<HTMLDivElement>(null);
    const [draggable, setDraggable] = useState<boolean>(false);
    const [offsetX, setOffsetX] = useState<number>(0);
    // const [resizable1Width, setResizable1Width] = useState<number>(70);

    useEffect(() => {
        if (draggable) {
            document.addEventListener("mousemove", handleDrag);
            document.addEventListener("mouseup", stopDrag);
        }
        return () => {
            document.removeEventListener("mousemove", handleDrag);
            document.removeEventListener("mouseup", stopDrag);
        };
        // eslint-disable-next-line
    }, [draggable]);
    const startDrag = (event: React.MouseEvent<HTMLDivElement>) => {
        const draggableElement = draggableRef.current;
        if (draggableElement) {
            setDraggable(true);
            const offsetX = event.clientX - draggableElement.getBoundingClientRect().left;
            setOffsetX(offsetX);
            // Disable text selection and user-select while dragging
            document.body.style.userSelect = 'none';
            // // Attach mousemove event listener
            // document.addEventListener("mousemove", handleDrag);
        }
    };

    const widthRef = useRef(0)
    const handleDrag = (event: MouseEvent) => {
        let temp = sessionStorage.getItem('blockRefOffSet');
        if (draggable && temp) {
            const blockWidth = Number(temp);
            const newResizable1Width = ((event.clientX - offsetX) / blockWidth) * 100;

            if (showTable) {
                dispatch(handleShowAndHideTable(!showTable))
            }
            // setResizable1Width(Math.max(0, Math.min(newResizable1Width, 100)));
            // dispatch(handleResizableWidth(Math.max(0, Math.min(newResizable1Width, 100))));
            widthRef.current = Math.max(0, Math.min(newResizable1Width, 100));
        }
    };


    const stopDrag = () => {
        // Enable text selection and user-select after dragging
        document.body.style.userSelect = 'auto';
        setDraggable(false);
        dispatch(handleResizableWidth(widthRef.current));


        // // Remove mousemove event listener
        // document.removeEventListener("mousemove", handleDrag);
    };

    // useEffect(() => {
    //     if (viewAnalytics) {
    //         setResizable1Width(70)
    //     } else {
    //         setResizable1Width(100)
    //     }

    // }, [viewAnalytics])

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm<UpdateAoiBufferDistanceSubmitForm>({
        resolver: yupResolver(aoiBufferDistanceUpdateInputValidation),
    });

    const onSubmit = (data: UpdateAoiBufferDistanceSubmitForm) => {
        if (Number(data.buffer_distance) === buffer_distance) {
            reset()
            dispatch(clearAoiNameSelForEdit());
            if (!leftTab) {
                dispatch(toggleLeftTab(true))
            } else {
                dispatch(toggleLeftTab(false));
            }
            return
        }
        dispatch(updateAoiData(access_token, { buffer_distance: data.buffer_distance, aoi_id })).then((res) => {
            if (res.status === 200) {
                reset()
                dispatch(clearAoiNameSelForEdit());
                dispatch(clearAoiList());
                if (!leftTab) {
                    dispatch(toggleLeftTab(true))
                } else {
                    dispatch(toggleLeftTab(false));
                }
            }
        })
    }

    useEffect(() => {
        if (lastAddedGeom && lastAddedGeomCRS) {
            if (!(savedAoiData.length < max_allowed_aoi) && location.pathname === aoiPathname) {
                toast.info(`Each company is limited to ${Number(
                    max_allowed_aoi
                )} AOI’s per user. If you would like to continue to add more shapes, please alter your existing shapefiles and re-import them as an AOI.`)
                return
            }
            dispatch(handleUsingMapCreateAoi(true));
            dispatch(toggleCreateAoiModal());
        }
        // eslint-disable-next-line
    }, [JSON.stringify(lastAddedGeom),
    // eslint-disable-next-line
    JSON.stringify(lastAddedGeomCRS)])

    useEffect(() => {
        !createAoiModal && usingMapCreateAoi && dispatch(handleUsingMapCreateAoi(false));
        // eslint-disable-next-line
    }, [createAoiModal])

    useEffect(() => {
        if (leftTab && aoi_name) {
            reset();
            dispatch(clearAoiNameSelForEdit)
        }
    }, [leftTab])

    return (
        <>
            <div className={`cart-map ${location.pathname === saveFiltersPathname ? "isMapHide" : ""}`}
                id="Resizable1"
                style={{
                    ...(viewAnalytics && { width: `calc(${resizableWidth}% - 80px)` }),
                }}
                onClick={() => {
                    if (location.pathname === searchPathname) {
                        //this is to close the menu when use click outside
                        dispatch(showHideColProperties(false));
                        // csvDownOpt && dispatch(showHideCsvDownOpt());
                        showSegmentDropDown && dispatch(handleShowAndHideSegmentDropDown(false))
                    }
                }}
            >
                <AnalyticsFullScreen />
                <figure>
                    {
                        // location.pathname !== searchPathname ?
                        (location.pathname !== saveFiltersPathname) ?
                            <div className="expandcollapsefilterbtn" onClick={((e) => {
                                e.preventDefault();
                                if (!leftTab) {
                                    if (location.pathname === aoiPathname) {
                                        if (aoi_name) {
                                            reset();
                                            dispatch(clearAoiNameSelForEdit());
                                        }
                                    }
                                    viewAnalytics && dispatch(toggleViewAnalytics());
                                    dispatch(toggleLeftTab(true))
                                } else {
                                    dispatch(toggleLeftTab(false));
                                }
                            })}>
                                {!leftTab ? <img src="images/angle-left.png" alt="" /> : <img src="images/angle-right.png" alt="" />}
                            </div>
                            : <></>
                    }
                    <MapComponent mapType={MapType.AOI} allowCreateAoI={location.pathname === aoiPathname} />
                </figure>

                {(!leftTab && aoi_name) ? (
                    <form
                        className="form-block"
                        onSubmit={handleSubmit(onSubmit)}
                        autoComplete="off"
                        autoCapitalize="off"
                    >
                        <div className="aoi-sidebar-btn">
                            <ul>
                                <li>
                                    <div className="saveCart">
                                        <h3>
                                            <span>Editing</span>
                                            {aoi_name}
                                        </h3>
                                        <div className="formGroup">
                                            <InputComponent
                                                name="buffer_distance"
                                                type={"number"}
                                                label="Buffer Distance (miles)"
                                                placeholder="Enter miles"
                                                register={register}
                                                defaultValue={buffer_distance}
                                                errorMsg={"buffer_distance" in errors ? errors.buffer_distance?.message : ''}
                                            />
                                        </div>
                                        <p className="small">Add a buffer around your AOI</p>
                                        <div className="action-btn">
                                            {/* <Link to="">
                                            <img
                                                src="images/aoi-icons7.svg"
                                                alt=""
                                            />
                                        </Link>
                                        <Link to="">
                                            <img
                                                src="images/aoi-icons8.svg"
                                                alt=""
                                            />
                                        </Link>
                                        <Link to="">
                                            <img
                                                src="images/aoi-icons9.svg"
                                                alt=""
                                            />
                                        </Link> */}
                                            <button
                                                type="submit"
                                                className="btn btn-primary"
                                            >
                                                <i className="fa-regular fa-floppy-disk"></i>{" "}
                                                Save
                                            </button>
                                            <button
                                                type="button"
                                                onClick={() => {
                                                    reset()
                                                    dispatch(clearAoiNameSelForEdit());
                                                    if (!leftTab) {
                                                        dispatch(toggleLeftTab(true))
                                                    } else {
                                                        dispatch(toggleLeftTab(false));
                                                    }
                                                }
                                                }
                                                className="btn btn-primary"
                                            >
                                                Cancel
                                            </button>
                                        </div>
                                    </div>
                                </li>
                                {/* <li>
                                <Link to="">
                                    <img src="images/aoi-icons3.svg" alt="" />
                                </Link>
                            </li> */}
                            </ul>
                        </div>
                    </form>
                ) : <></>}
                {/* hiding the search box as discussed in meeting */}
                {location.pathname === searchPathname || location.pathname === aoiPathname ? <WellsAndRigsCom /> : <></>}
            </div >


            {/* {viewAnalytics ? <> */}
            {/* <div
                    ref={draggableRef}
                    onMouseDown={startDrag}
                    className="Draggable"
                ></div> */}
            {/* {(viewAnalytics && (location.pathname === searchPathname || location.pathname === aoiPathname)) ? <AnalyticsRightSidebar resizableWidth={resizableWidth} startDrag={startDrag} draggableRef={draggableRef} /> : <></>} */}
            {((location.pathname === searchPathname || location.pathname === aoiPathname) || viewAnalytics) ? <AnalyticsRightSidebar resizableWidth={resizableWidth} startDrag={startDrag} draggableRef={draggableRef} /> : <></>}
            {/* </> : <></>} */}


            {/* create aoi modal */}
            {
                createAoiModal && location.pathname !== aoiPathname && (
                    <CreateAoiModal />
                )
            }
        </>
    );
}

export default CommonMainContent;
