import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
    CartListItemsType,
    CartSelectBasinCountyInitialValue,
    RemoveCartItemAction,
    InvoiceLineItemsAndTotal
} from "../../models/redux-models";

const initialState: CartSelectBasinCountyInitialValue = {
    basinSearchList: [],
    countySearchList: [],
    cartListItems: [],
    cartListItemsYearly: [],
    cartItemsTotalPerMonth: 0,
    cartItemsTotalPerYear: 0,
    stateOptions: [],
    cartItemsTotalTax: 0,
    saved_card: [],
    tax_percentage: 0,
    cartModified: false,
    lastCountyBasinName: "",
    yearlyDiscountPercent: 0,
    yearly: true,
    is_promotion_active: false,
    promoCodeDetails: {
        is_valid: "",
        discount_percent: null,
        discount_fixed: null,
        minimum_amount: null,
        promoCode: "",
    },
    yearlyBillToday: null,
    monthlyBillToday: null,
    invoiceLineItems: null,
    invoiceItemsSubtotal: null,
    invoiceItemsTax: null,
    invoiceItemsTotal: null,
    invoiceMonthlyCost: null
};

const cartSelectBasinCounty = createSlice({
    name: "cartSelectBasinCounty",
    initialState: initialState,
    reducers: {
        loadBasinSearchList(
            state,
            action: PayloadAction<
                CartSelectBasinCountyInitialValue["basinSearchList"]
            >
        ) {
            return {
                ...state,
                countySearchList: [],
                basinSearchList: action.payload,
            };
        },
        loadCountySearchList(
            state,
            action: PayloadAction<
                CartSelectBasinCountyInitialValue["countySearchList"]
            >
        ) {
            return {
                ...state,
                basinSearchList: [],
                countySearchList: action.payload,
            };
        },
        clearSearchList(state, action: PayloadAction) {
            return {
                ...state,
                basinSearchList: [],
                countySearchList: [],
            };
        },
        loadCartItems(
            state,
            action: PayloadAction<{
                cart_list_items_monthly: CartListItemsType;
                cart_list_items_yearly: CartListItemsType;
                yearlyDiscountPercent: CartSelectBasinCountyInitialValue["yearlyDiscountPercent"];
                yearlyBillToday: CartSelectBasinCountyInitialValue["yearlyBillToday"];
                monthlyBillToday: CartSelectBasinCountyInitialValue["monthlyBillToday"];
            }>
        ) {
            let tempArray = [...action.payload.cart_list_items_monthly];

            return {
                ...state,
                cartListItems: tempArray,
                cartListItemsYearly: action.payload.cart_list_items_yearly,
                yearlyBillToday: action.payload.yearlyBillToday ?? state.yearlyBillToday,
                monthlyBillToday: action.payload.monthlyBillToday ?? state.monthlyBillToday,
                cartItemsTotalPerMonth: tempArray
                    .filter((item) => item.is_deleted === false)
                    .reduce(
                        (accumulator, currentValue) =>
                            accumulator + currentValue.price_per_month,
                        0
                    ),
                cartItemsTotalPerYear: action.payload.cart_list_items_yearly
                    .filter((item) => item.is_deleted === false)
                    .reduce(
                        (accumulator, currentValue) =>
                            accumulator + currentValue.price,
                        0
                    ),
                cartModified:
                    tempArray.filter(
                        (item) =>
                            item.is_deleted === true ||
                            item.subscription_details_id === null
                    ).length > 0
                        ? true
                        : false,
                yearlyDiscountPercent: action.payload.yearlyDiscountPercent,
            };
        },
        removeCartItems(state, action: PayloadAction<RemoveCartItemAction>) {
            let selectedObject = state.cartListItems.filter(
                (item) => item.id === action.payload.item_id
            );
            return {
                ...state,
                cartItemsTotalPerYear: state.cartItemsTotalPerMonth - selectedObject[0].price,
                cartListItems: state.cartListItems.filter(
                    (item) => item.id !== action.payload.item_id
                ),
            };
        },
        clearCartItemsList(state, action: PayloadAction) {
            return {
                ...state,
                cartItemsTotalPerMonth: 0,
                cartListItems: [],
                cartItemsTotalTax: 0,
                tax_percentage: 0,
                // yearlyDiscountPercent: 0,
            };
        },
        loadStateOptions(
            state,
            action: PayloadAction<
                CartSelectBasinCountyInitialValue["stateOptions"]
            >
        ) {
            return {
                ...state,
                stateOptions: [...action.payload],
            };
        },
        loadCartItemTotalTax(
            state,
            action: PayloadAction<{
                totalTax: CartSelectBasinCountyInitialValue["cartItemsTotalTax"];
                tax_percentage: number;
            }>
        ) {
            return {
                ...state,
                cartItemsTotalTax: action.payload.totalTax,
                tax_percentage: action.payload.tax_percentage,
            };
        },
        clearCartItemsTotalTax(state, action: PayloadAction) {
            return {
                ...state,
                cartItemsTotalTax: 0,
                tax_percentage: 0,
                ...(state.is_promotion_active && {
                    is_promotion_active: false,
                }),
            };
        },
        loadSavedCardDetails(
            state,
            action: PayloadAction<
                CartSelectBasinCountyInitialValue["saved_card"]
            >
        ) {
            return {
                ...state,
                saved_card: action.payload,
            };
        },
        clearSavedCardDetails(state, action: PayloadAction) {
            return {
                ...state,
                saved_card: [],
            };
        },
        handleLastCountyBasinName(
            state,
            action: PayloadAction<
                CartSelectBasinCountyInitialValue["lastCountyBasinName"]
            >
        ) {
            return {
                ...state,
                lastCountyBasinName: action.payload,
            };
        },
        handleYearlyAndMonthlyTab(
            state,
            action: PayloadAction<CartSelectBasinCountyInitialValue["yearly"]>
        ) {
            return {
                ...state,
                yearly: action.payload,
            };
        },
        handlePromotionCodeActive(
            state,
            action: PayloadAction<
                CartSelectBasinCountyInitialValue["is_promotion_active"]
            >
        ) {
            return {
                ...state,
                is_promotion_active: action.payload,
            };
        },
        setInvoiceLineItemsAndTotal(
            state,
            action: PayloadAction<
                InvoiceLineItemsAndTotal
            >
        ) {
            return {
                ...state,
                invoiceLineItems: action.payload.invoiceLineItems,
                invoiceItemsSubtotal: action.payload.invoiceItemsSubtotal,
                invoiceItemsTax: action.payload.invoiceItemsTax,
                invoiceItemsTotal: action.payload.invoiceItemsTotal,
                invoiceMonthlyCost: action.payload.invoiceMonthlyCost || null
            };
        },
        handlePromoCodeDetails(
            state,
            action: PayloadAction<{
                is_valid?: CartSelectBasinCountyInitialValue["promoCodeDetails"]["is_valid"];
                discount_percent?: CartSelectBasinCountyInitialValue["promoCodeDetails"]["discount_percent"];
                discount_fixed?: CartSelectBasinCountyInitialValue["promoCodeDetails"]["discount_fixed"];
                minimum_amount?: CartSelectBasinCountyInitialValue["promoCodeDetails"]["minimum_amount"];
                promoCode?: CartSelectBasinCountyInitialValue["promoCodeDetails"]["promoCode"];
                reset?: CartSelectBasinCountyInitialValue["promoCodeDetails"]["reset"];
            }>
        ) {
            return {
                ...state,
                promoCodeDetails: action.payload.reset
                    ? { ...initialState.promoCodeDetails }
                    : {
                        ...state.promoCodeDetails,
                        ...(action.payload.is_valid && {
                            is_valid: action.payload.is_valid,
                        }),
                        ...(action.payload.discount_percent && {
                            discount_percent: action.payload.discount_percent,
                        }),
                        ...(action.payload.discount_fixed && {
                            discount_fixed: action.payload.discount_fixed,
                        }),
                        ...(action.payload.minimum_amount && {
                            minimum_amount: action.payload.minimum_amount,
                        }),
                        ...(action.payload.promoCode && {
                            promoCode: action.payload.promoCode,
                        }),
                    },
            };
        },
    },
});

export default cartSelectBasinCounty;
